/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css2?family=Jacquarda+Bastarda+9&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Astloch:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Silkscreen:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=VT323&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Tiny5&display=swap");
@font-face {
  font-family: "Chicago";
  src: url("../public/fonts/ChicagoFLF.ttf");
}
@font-face {
  font-family: "Abadi Extra Light";
  src: url("../public/fonts/AbadiMT-ExtraLight.ttf");
}
@font-face {
  font-family: "Minecraftia";
  src: url("../public/fonts/Minecraftia-Regular.ttf");
}
@font-face {
  font-family: "Rainy Hearts";
  src: url("../public/fonts/rainyhearts.ttf");
}

html {
  margin: 0;
  padding: 0;
}

body {
  background-color: black;
  color: white;
  -webkit-font-smoothing: none;
  font-smooth: none;
  image-rendering: pixelated;
  margin: 0;
  width: 100%;
  max-width: 100%;
}

.text {
  letter-spacing: 6.2px;
  font-family: "Jacquarda Bastarda 9";
  font-size: 17px;
  fill: white;
}

#rotatingText {
  animation-name: rotate-circle;
  animation-duration: 20s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes rotate-circle {
  to {
    transform: rotate(1turn);
  }
}

.ticker-wrap {
  position: fixed;
  bottom: 0;
  width: 80%;
  overflow: hidden;
  height: 4rem;
  background-color: rgba(#000, 0.9);
  padding-left: 100%;
  box-sizing: content-box;
  border: 1px solid white;
}

.ticker {
  display: inline-block;
  height: 4rem;
  line-height: 4rem;
  white-space: nowrap;
  padding-right: 100%;
  box-sizing: content-box;
  border: 1px solid green;

  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-name: ticker;
  animation-name: ticker;
  -webkit-animation-duration: 30s;
  animation-duration: 30s;
}

.ticker__item {
  display: inline-block;
  padding: 0 2rem;
  font-size: 25px;
  color: black;
}
